import { format as formatDate, isValid } from "date-fns";

export const resolveDate = (
    value: string | undefined | null | Date,
    format = "dd/MM/yyyy - HH:mm:ss"
) => {
    if (value) {
        const date = new Date(value);
        
        return isValid(date) ? formatDate(date, format) : `${value}`;
    }
    return "";
};