import Archive from "pages/Archive/Archive";
import AddPersonPage from "../pages/AddPersonPage/AddPersonPage";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Suspense } from "react";
import { routes } from "config/routes";

export function Rout() {
    return (
        <Suspense fallback={null}>
            <Router>
                <Routes>
                    <Route path={routes.root} element={<AddPersonPage />} />
                    <Route path={routes.archive} element={<Archive />} />
                </Routes>
            </Router>
        </Suspense>
    )
}

// export const router = createBrowserRouter([
//     {
//         path: '/',
//         element: <App />,
//         errorElement: <ErrorPage />,
//     },
//     {
//         path: 'login',
//         element: <LoginPage />,
//     },
//     {
//         path: 'reports',
//         element: <ReportsPage />,
//     },
//     {
//         path: 'user',
//         element: <UserPage />,
//     },
//     {
//         path: 'main-user',
//         element: <MainUserPage />,
//     },
//     {
//         path: 'main-user-table',
//         element: <ReportsTable />,
//     },
// ]);