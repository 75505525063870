import { AxiosResponse } from "axios";
import $api from "../http";

export default class Services {
    static async addPerson(formData: any): Promise<AxiosResponse> {
        return $api.post('/upload', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    }
    static async getPersons(data: any): Promise<AxiosResponse> {
        return $api.post('/search', data);
    }

    static async getArchivedData(data: any): Promise<AxiosResponse> {
        const reponse = $api.post('/search', {
            q: data.search,
            p: data.page,
        },
        )
        return reponse;
    }
}