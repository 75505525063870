import { useRef, useState } from "react";
import { Box, Typography } from "@mui/material";
import { formatSeconds } from "utils/formatSeconds";
import styles from "./VideoItem.module.css"

type Props = {
  name: string;
  date: string;
  videoLink: string;
  handleClick: (src: string, name: string) => void;
};
export const VideoItem = ({ name, date, videoLink, handleClick }: Props) => {
  const video = useRef<HTMLVideoElement>(null);
  const [duration, setDuration] = useState(0);

  const handleLoadedMetadata = () => {
    const videoElement = video.current;
    setDuration(videoElement?.duration || 0);
  };

  return (
    <Box
      onClick={() => {
        handleClick(videoLink, name);
      }}
      sx={{
        mr: "30px",
        mb: "10px",

        "@media screen and (max-width: 550px)": {
          width: "100%",
          mr: "0px",
        },
      }}
    >
      <Box
        sx={{
          width: "230px",
          height: "130px",
          borderRadius: "8px",
          overflow: "hidden",
          position: "relative",
          mb: "10px",

          "@media screen and (max-width: 550px)": {
            width: "100%",
            height: "200px",
          },
        }}
      >
        <video
          ref={video}
          className={styles.video}
          src={videoLink}
          onLoadedMetadata={handleLoadedMetadata}
          style={{
            position: "relative",
            left: 0,
            top: 0,
            opacity: 1,
          }}
        ></video>
        <Box
          sx={{
            position: "absolute",
            right: "1px",
            bottom: "2px",
            p: "5px",
            borderRadius: "8px",
            background: "black",
            opacity: "0.7",
          }}
        >
          <Typography
            sx={{
              fontSize: "12px",
              color: "white",
            }}
          >
            {formatSeconds(duration)}
          </Typography>
        </Box>
      </Box>
      <Typography
        sx={{
          mb: "5px",
          fontSize: "14px",
          color: "white",
        }}
      >
        {name}
      </Typography>
      <Typography
        sx={{
          fontSize: "14px",
          color: "#696969",
        }}
      >
        {date}
      </Typography>
    </Box>
  );
};
