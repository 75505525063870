import React, { FC, useRef, useState } from "react";
import {
  Button,
  FormControl,
  FormHelperText,
  OutlinedInput,
  Box,
  useTheme,
  useMediaQuery,
  Typography,
} from "@mui/material";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import s from "./AddPersonForm.module.css";
import Services from "../../services/Services";
import fileIcon from "../../assets/images/file.png";
import ReCAPTCHA from "react-google-recaptcha";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../Loader/Loader";
import { errorConfig } from "./constants";

const signInSchema = yup.object().shape({
  location: yup
    .string()
    .trim()
    .min(2, "המיקום קצר מדי(מעל ל 2 תווים)")
    .max(50, "המיקום ארוך מדי(מתחת ל 50 תווים")
    .required("זהו שדה חובה"),
  tag: yup.string().trim(),
  video: yup.mixed().transform((value) => (value === "" ? undefined : value)),
  description: yup.string().trim().required("תיאור הוא שדה חובה"),
});

type FormData = {
  location?: string;
  video?: any;
  tag?: string;
  description?: string;
};

const AddPersonForm: FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(signInSchema),
  });
  const [fileName, setFileName] = useState<string>("");
  const [errorFile, setErrorFile] = useState<string>("");
  const [showLoader, setShowLoader] = useState<Boolean>(false);
  const [file, setFile] = useState<any>(undefined);
  const [isCaptchaValid, setIsCaptchaValid] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setErrorFile("");
      setFile(file);
      setFileName(file.name);
    }
  };

  const fileInputRef = useRef<HTMLInputElement>(null);
  const form = useRef<HTMLFormElement>(null);
  const captchaRef = useRef<ReCAPTCHA>(null);

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const addPerson = async (formData: any) => {
    try {
      setShowLoader(true);
      if (file) {
        await Services.addPerson(formData);
        setFile(undefined);
        setFileName("");
        form?.current?.reset();
        toast(
          "הסרטון שחלקת עם הארכיון הועלה בהצלחה, תודה על השיתוף! כל מידע נוסף שיש ברשותך אנא תחלוק עימנו, בברכה, הארכיון",
          {
            type: "success",
          }
        );
      } else {
        setErrorFile("נא לטעון קובץ");
      }
    } catch (error: any) {
      toast((errorConfig as any)[error?.response?.data?.status] || "", {
        type: "error",
      });
    } finally {
      setShowLoader(false);
      setIsCaptchaValid(false);
      captchaRef?.current?.reset();
    }
  };
  const onSubmit: SubmitHandler<FormData> = (data: any) => {
    const token = captchaRef?.current?.getValue();
    const formData = {
      location: data.location,
      data: file,
      tag: data.tag || "",
      description: data.description || "",
      secret: token,
    };
    addPerson(formData);
  };

  return (
    <Box
      sx={{
        p: "40px",
        width: "45%",
        minWidth: "375px",
        maxWidth: "570px",
        minHeight: "450px",
        boxShadow: "0px 0px 50px 0px #403F3F4DБ",
        opacity: "0.94",
        height: isMobile ? "auto" : "70vh",
        borderRadius: "40px",
        backgroundColor: "white",
        position: "relative",
      }}
    >
      {showLoader && (
        <Loader
          styles={{ color: "#F00100" }}
          boxStyles={{
            width: "100%",
            height: "100%",
            background: "white",
            opacity: "0.8",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            borderRadius: "40px",
            zIndex: 3,
            left: 0,
            top: 0,
          }}
        />
      )}
      {/* <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button href="/">X</Button>
      </Box> */}
      <form
        ref={form}
        dir="rtl"
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <FormControl
          error={errors.location ? true : false}
          sx={{ width: "100%", mb: 2 }}
          variant="outlined"
        >
          <OutlinedInput
            dir="rtl"
            id="outlined-adornment-name"
            type="text"
            {...register("location", {
              required: "Name entered incorrectly",
            })}
            sx={{
              borderRadius: "10px",
            }}
            placeholder=" מיקום"
          />
          {errors?.location && (
            <FormHelperText dir="rtl" id="component-error-text">
              {errors.location?.message}
            </FormHelperText>
          )}
        </FormControl>
        {!isMobile && (
          <FormControl
            dir="rtl"
            error={errors.video ? true : false}
            sx={{ alignItems: "center", width: "100%", mb: 2 }}
            variant="outlined"
          >
            {errorFile && (
              <FormHelperText id="component-error-text" sx={{ color: "red" }}>
                {errorFile}
              </FormHelperText>
            )}
            <div dir="rtl" className={s.file_input_container}>
              <input
                dir="rtl"
                type="file"
                name="file"
                id="file-input"
                className={s.file_input}
                ref={fileInputRef}
                onChange={handleFileChange}
              />
              <label
                dir="rtl"
                htmlFor="file-input"
                className={s.custom_file_input_label}
              >
                <span
                  style={{
                    position: "absolute",
                    right: "160px",
                    textAlign: "left",
                  }}
                >
                  {fileName ? fileName : "MOV, AVI, MP4, FLV, MPEG"}
                </span>
                <Button
                  variant="contained"
                  onClick={handleButtonClick}
                  sx={{
                    position: "absolute",
                    backgroundColor: "#404040",
                    right: 0,
                    height: "100%",
                    width: "30%",
                    borderRadius: 0,
                    textTransform: "none",
                  }}
                >
                  בחר
                </Button>
              </label>
            </div>
          </FormControl>
        )}
        <FormControl
          error={errors.tag ? true : false}
          sx={{ width: "100%", mb: 2 }}
          variant="outlined"
        >
          <OutlinedInput
            dir="rtl"
            id="outlined-adornment-name"
            type="text"
            {...register("tag", {
              required: "Name entered incorrectly",
            })}
            sx={{
              borderRadius: "10px",
            }}
            placeholder="#תגית"
          />
          {errors?.tag && (
            <FormHelperText dir="rtl" id="component-error-text">
              {errors.tag?.message}
            </FormHelperText>
          )}
        </FormControl>
        <FormControl
          error={errors.description ? true : false}
          sx={{ width: "100%", mb: 3 }}
          variant="outlined"
        >
          <OutlinedInput
            dir="rtl"
            id="outlined-adornment-name"
            type="text"
            {...register("description", {
              required: "Name entered incorrectly",
            })}
            sx={{
              borderRadius: "10px",
            }}
            placeholder="הוסף תיאור בנוגע לסרטון"
          />
          {errors?.description && (
            <FormHelperText dir="rtl" id="component-error-text">
              {errors.description?.message}
            </FormHelperText>
          )}
        </FormControl>
        <Box
          sx={{
            mb: 2,
          }}
        >
          <ReCAPTCHA
            onChange={(newToken) => {
              setIsCaptchaValid(!!newToken);
            }}
            hl="he"
            ref={captchaRef}
            sitekey={`${process.env.REACT_APP_SITE_KEY}`}
          />
        </Box>
        {isMobile && (
          <>
            {fileName && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  flexDirection: "row-reverse",
                  mb: "10px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row-reverse",
                    width: "70%",
                    overflow: "hidden",
                  }}
                >
                  <img
                    style={{
                      marginRight: "5px",
                    }}
                    src={fileIcon}
                    alt="file"
                  />
                  <Typography
                    sx={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      fontSize: "10px",
                    }}
                  >
                    {fileName || ""}
                  </Typography>
                </Box>
                <Box
                  onClick={() => {
                    setFile(undefined);
                    setFileName("");
                  }}
                >
                  X
                </Box>
              </Box>
            )}
            <FormControl
              dir="rtl"
              error={errors.video ? true : false}
              sx={{ alignItems: "center", width: "100%", mb: 2 }}
              variant="outlined"
            >
              <div dir="rtl" className={s.file_input_container_mobile}>
                <input
                  dir="rtl"
                  name="file"
                  type="file"
                  id="file-input"
                  className={s.file_input}
                  ref={fileInputRef}
                  onChange={handleFileChange}
                />
                <Button
                  variant="contained"
                  onClick={handleButtonClick}
                  sx={{
                    backgroundColor: "#404040",
                    position: "absolute",
                    right: 0,
                    height: "60px",
                    width: "100%",
                    borderRadius: 0,
                    textTransform: "none",
                  }}
                >
                  בחר קובץ
                </Button>
              </div>
            </FormControl>
          </>
        )}
        <Button
          disabled={!isCaptchaValid}
          sx={{
            width: "100%",
            height: "60px",
            textTransform: "none",
            background: "red",
            borderRadius: "10px",
          }}
          variant="contained"
          type="submit"
        >
          הוסף
        </Button>
      </form>
      <ToastContainer />
    </Box>
  );
};

export default AddPersonForm;
