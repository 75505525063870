// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VideoItem_video__SGG5\\+ {
    object-fit: fill;
}


@media screen and (max-width: 5500px) {
    .VideoItem_video__SGG5\\+ {
        width: 100%;
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/Archive/components/VideoItem/VideoItem.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;;AAGA;IACI;QACI,WAAW;IACf;AACJ","sourcesContent":[".video {\n    object-fit: fill;\n}\n\n\n@media screen and (max-width: 5500px) {\n    .video {\n        width: 100%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"video": `VideoItem_video__SGG5+`
};
export default ___CSS_LOADER_EXPORT___;
