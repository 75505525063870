import { useEffect, useState } from "react";
import { Box, Button, Pagination, TextField, Typography } from "@mui/material";
import videoIcon from "assets/images/videoIcon.png";
import search from "assets/images/search.png";
import { routes } from "config/routes";
import { VideoItem } from "./components/VideoItem";
import { resolveDate } from "utils/resolveDate";
import { VideoModal } from "./components/VideoModal/VideoModal";
import Services from "services/Services";
import { useDebounce } from "utils/hooks/useDebounce";

interface IArchivedData {
  description: string;
  date: string;
  url: string;
  extension: string;
}

type ArchiveType = Array<IArchivedData>;

const Archive = () => {
  const [archiveData, setArchiveData] = useState<ArchiveType>([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [pagesCount, setPagesCount] = useState(0);
  const [name, setName] = useState("");
  const [open, setOpen] = useState(false);
  const [link, setLink] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const debouncedValue = useDebounce(searchValue, 300);

  const handleSearch = (e: any) => {
    setSearchValue(e.target.value);
  };

  const getData = async () => {
    try {
      const { data }: any = await Services.getArchivedData({
        page: pageNumber,
        search: searchValue,
      });

      setArchiveData(data.data);
      setPagesCount(Math.ceil(data.totalcount / 20));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
  }, [pageNumber, debouncedValue]);

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setPageNumber(page);
  };

  const handleVideo = (link: string, name: string) => {
    setLink(link);
    setOpen(true);
    setName(name);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        width: "100%",
        height: "100%",
        background: "#000000",
        p: "45px 80px",
        overflow: "auto",

        "@media screen and (max-width: 550px)": {
          p: "20px",
        },
      }}
    >
      <Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mb: "25px",

            "@media screen and (max-width: 550px)": {
              flexDirection: "column",
            },
          }}
        >
          <Box />
          <TextField
            id="search"
            placeholder="Search"
            value={searchValue}
            onChange={handleSearch}
            InputProps={{
              endAdornment: (
                <img
                  onClick={getData}
                  src={search}
                  alt="search"
                  style={{
                    marginLeft: "5px",
                    cursor: "pointer",
                  }}
                />
              ),
            }}
            sx={{
              border: "1px solid #FFFFFF",
              color: "white",
              borderRadius: "8px",
              backgroundColor: "#000000",

              "@media screen and (max-width: 550px)": {
                width: "100%",
                mb: "20px",
              },

              "& .MuiOutlinedInput-input": {
                p: "10px",
              },

              "& .MuiOutlinedInput-root": {
                color: "white",
              },
            }}
          />
          <Button
            href={routes.root}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              border: "1px solid #FFFFFF",
              borderRadius: "8px",
              p: "10px",

              "@media screen and (max-width: 550px)": {
                width: "100%",
              },
            }}
          >
            <Typography
              sx={{
                mr: "5px",
                fontSize: "14px",
                color: "#FFFFFF",
              }}
            >
              Add Video
            </Typography>
            <img src={videoIcon} alt="videoIcon" />
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            mb: "15px",

            "@media screen and (max-width: 550px)": {
              justifyContent: "center",
            },
          }}
        >
          {archiveData?.map((item) => (
            <VideoItem
              key={item?.url}
              name={item?.description}
              videoLink={item?.url}
              date={resolveDate(item.date, "dd/MM/yyyy")}
              handleClick={handleVideo}
            />
          ))}
        </Box>
      </Box>
      {pagesCount > 1 && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",

            "@media screen and (max-width: 550px)": {
              justifyContent: "center",
            },
          }}
        >
          <Pagination
            sx={{
              color: "white",

              "& .MuiPaginationItem-root": {
                height: "35px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "2px",
                color: "#F01818",
                backgroundColor: "white",
              },
            }}
            count={pagesCount}
            page={pageNumber}
            shape="rounded"
            onChange={handlePageChange}
          />
        </Box>
      )}

      <VideoModal
        open={open}
        src={link}
        handleClose={handleClose}
        name={name}
      />
    </Box>
  );
};

export default Archive;
