import React, { FC } from "react";
import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import AddPersonForm from "components/AddPersonFrom/AddPersonForm";
import styles from "./AddPersonPage.module.css";
import warCrime from "assets/images/warCrime.png";
import logo from "assets/images/logo.svg";
import { routes } from "config/routes";

const AddPersonPage: FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        background:
          "linear-gradient(186.98deg, #000000 40.88%, #686868 99.67%)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "550px",
          padding: "60px 20px",
          backgroundSize: "cover",
          backgroundImage: `url(${warCrime})`,
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img className={styles.logo} src={logo} alt="logo" />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mb: "40px",
          }}
        >
          <Box>
            <Typography
              className={styles.title}
              sx={{
                fontSize: isMobile ? "37px" : "7rem",
                color: "white",
                fontWeight: "bold",
              }}
            >
              War crimes archive
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",

                "@media screen and (max-width: 550px)": {
                  flexDirection: "column",
                },
              }}
            >
              <Box
                sx={{
                  "@media screen and (max-width: 550px)": {
                    mb: "10px",
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      mr: "20px",
                      color: "white",
                    }}
                  >
                    Remembering
                  </Typography>
                  <Box
                    sx={{
                      padding: "5px",
                      backgroundColor: "#F00100",
                      opacity: "0.9",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "white",
                        fontSize: isMobile ? "18px" : "24px",
                      }}
                    >
                      October 7, 2023
                    </Typography>
                  </Box>
                </Box>
                <Typography
                  sx={{
                    color: "white",
                    fontSize: isMobile ? "18px" : "24px",
                  }}
                >
                  An Archive of Difficult Moments
                </Typography>
              </Box>
              <Button
                sx={{
                  minWidth: "315px",
                  width: "30%",
                  height: "60px",
                  background: "white",
                  color: "black",

                  "&:hover": {
                    background: "#F00100",
                    color: "white"
                  },
                }}
                href={routes.archive}
              >
                MOVE TO VIDEO
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: isMobile ? "center" : "space-around",
          flexDirection: isMobile ? "column" : "row",
          alignItems: "center",
          minHeight: "100vh",
          boxSizing: "border-box",
          padding: isMobile ? 1 : 0,
          margin: 0,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            maxWidth: isMobile ? "100%" : "550px",
            maxHeight: isMobile ? "unset" : "800px",
            boxSizing: "border-box",
            padding: 0,
            marginLeft: 2,
            marginRight: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "end",
            }}
          >
            <Typography
              variant="body1"
              sx={{
                mb: isMobile ? "20px" : "10px",
                direction: "rtl",
                color: "white",
                textAlign: isMobile ? "center" : "right",
              }}
            >
              לצורך תיעוד הזוועות ואכזריות כלפי מדינת ישראל מאז 7.10.23 אנא
              הכניסו כל סרטון שאתם יכולים. סרטונים אשר ממחישים מי הם אויביי
              ישראל. הראיות הללו לפשעי המלחמה אשר ביצעו כלפינו קריטיות למדינת
              ישראל, להגנה עליה ולדורות הבאים.
            </Typography>
          </Box>
        </Box>
        <AddPersonForm />
      </Box>
    </Box>
  );
};

export default AddPersonPage;
