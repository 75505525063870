import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';

// A custom theme for this app
const theme = createTheme({
    palette: {
        primary: {
            main: '#6585B4',
        },
        secondary: {
            main: '#19857b',
        },
        error: {
            main: red.A400,
        },
    },
    components: {
        MuiTypography: {
            styleOverrides: {
                body1: {
                    fontSize: "24px",
                    fontWeight: "400"
                },
            },
        },
        MuiPaginationItem: {
            styleOverrides: {
                root: {
                    "&.Mui-selected": {
                        color: "white",
                        backgroundColor: "#F00100"
                    }
                },
                icon: {
                    rotate: "180deg",
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    "& .MuiOutlinedInput-notchedOutline": {
                        borderRadius: "10px",
                        border: "none",

                        "&.Mui-focused": {
                            border: "none"
                        }
                    },

                    "& .MuiOutlinedInput-root": {
                        "&.Mui-focused": {
                            border: "none"
                        }
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: "none",
                },
            },
        },
    },
    direction: 'rtl',
});

export default theme;