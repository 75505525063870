// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AddPersonForm_file_input_container__D1PlM {
    width: 100%;
    border-radius: 10px;
    border: 1px solid #A9B1C3;
    height: 50px;
    position: relative;
    overflow: hidden;
    display: inline-block;
}

.AddPersonForm_file_input__Wr5L1 {
    position: absolute;
    right: 0;
    top: 0;
    opacity: 0;
}

.AddPersonForm_custom_file_input_label__rmpM6 {
    display: flex;
    align-items: center;
    padding-left: 10px;
    color: gray;
    height: 100%;
    border-radius: 10px 10px 10px 10px;
    cursor: pointer;
}

.AddPersonForm_file_input_container_mobile__OVZ5G {
    width: 100%;
    border-radius: 10px;
    border: 1px solid #A9B1C3;
    height: 60px;
    position: relative;
    overflow: hidden;
    display: inline-block;
}`, "",{"version":3,"sources":["webpack://./src/components/AddPersonFrom/AddPersonForm.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,mBAAmB;IACnB,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,gBAAgB;IAChB,qBAAqB;AACzB;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,MAAM;IACN,UAAU;AACd;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,kCAAkC;IAClC,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,mBAAmB;IACnB,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,gBAAgB;IAChB,qBAAqB;AACzB","sourcesContent":[".file_input_container {\n    width: 100%;\n    border-radius: 10px;\n    border: 1px solid #A9B1C3;\n    height: 50px;\n    position: relative;\n    overflow: hidden;\n    display: inline-block;\n}\n\n.file_input {\n    position: absolute;\n    right: 0;\n    top: 0;\n    opacity: 0;\n}\n\n.custom_file_input_label {\n    display: flex;\n    align-items: center;\n    padding-left: 10px;\n    color: gray;\n    height: 100%;\n    border-radius: 10px 10px 10px 10px;\n    cursor: pointer;\n}\n\n.file_input_container_mobile {\n    width: 100%;\n    border-radius: 10px;\n    border: 1px solid #A9B1C3;\n    height: 60px;\n    position: relative;\n    overflow: hidden;\n    display: inline-block;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"file_input_container": `AddPersonForm_file_input_container__D1PlM`,
	"file_input": `AddPersonForm_file_input__Wr5L1`,
	"custom_file_input_label": `AddPersonForm_custom_file_input_label__rmpM6`,
	"file_input_container_mobile": `AddPersonForm_file_input_container_mobile__OVZ5G`
};
export default ___CSS_LOADER_EXPORT___;
