import { Box, CircularProgress, } from "@mui/material";
import React from "react";


export default function Loader  ({ styles, boxStyles }) {
  return (
    <Box sx={{ ...boxStyles }}>
      <CircularProgress
        sx={{
          ...styles,
        }}
      />
    </Box>
  );
};