import { Backdrop, Box, Fade, Modal, Typography } from "@mui/material";
import grayCancel from "assets/images/grayCancel.png";

type Props = {
  open: boolean;
  handleClose: () => void;
  src: string;
  name: string;
};

export const VideoModal = ({ open, src, handleClose, name }: Props) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        sx: {
          backdropFilter: "blur(3px)",
          backgroundColor: "rgba(0,0,30,0.4)",
        },
      }}
    >
      <Fade in={open}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            position: "absolute",
            top: "50%",
            left: "50%",
            maxWidth: "99%",
            maxHeight: "90%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            borderRadius: "12px",
            overflow: "auto",
            outline: "none",

            "@media screen and (max-width:500px)": {
              maxWidth: "99%",
            },
          }}
        >
          <Box
            sx={{
              padding: "10px",
              backgroundColor: "#636363",
            }}
          >
            <Box
              onClick={handleClose}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                mb: "10px",
                cursor: "pointer",
              }}
            >
              <img
                style={{
                  width: "30px",
                  height: "30px",
                }}
                src={grayCancel}
                alt="grayCancel"
              />
            </Box>
            <video
              autoPlay
              style={{
                maxWidth: "calc(100vw - 25px)",
                maxHeight: "calc(100vh - 200px)",
              }}
              src={src}
              controls
            ></video>
            <Box>
              <Typography
                sx={{
                  color: "white",
                }}
              >
                {name}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};
