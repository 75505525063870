// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AddPersonPage_logo__yP3Cc {
  margin-bottom: 20px;
}

.AddPersonPage_title__VteOE {
  font-size: 7rem;
}

@media screen and (max-width: 680px) {
  .AddPersonPage_title__VteOE {
    font-size: 62px;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/AddPersonPage/AddPersonPage.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE;IACE,eAAe;EACjB;AACF","sourcesContent":[".logo {\n  margin-bottom: 20px;\n}\n\n.title {\n  font-size: 7rem;\n}\n\n@media screen and (max-width: 680px) {\n  .title {\n    font-size: 62px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo": `AddPersonPage_logo__yP3Cc`,
	"title": `AddPersonPage_title__VteOE`
};
export default ___CSS_LOADER_EXPORT___;
